

if (!Array.prototype.toReversed) {
  Array.prototype.toReversed = function() {
    return this.slice().reverse();
  };
}

if (!Array.prototype.toSpliced) {
  Array.prototype.toSpliced = function(start: number, deleteCount: number, ...items: any[]) {
    const newArr = this.slice();
    newArr.splice(start, deleteCount, ...items);
    return newArr;
  };
}

if (!Array.prototype.toSorted) {
  Array.prototype.toSorted = function(compareFn: (a: any, b: any) => number) {
    return this.slice().sort(compareFn);
  };
}

if (!Array.prototype.with) {
  Array.prototype.with = function(index: number, value: any) {
    if (index < 0 || index >= this.length) {
      throw new RangeError('Invalid index');
    }
    const newArr = this.slice();
    newArr[index] = value;
    return newArr;
  };
}